import React from 'react';
import styled from 'styled-components';
import SlideText from 'components/LV1/AosSlideText';

const Wrap = styled.div<{ isNoMaxWidth: boolean }>`
  position :relative;
  z-index: 1;
  max-width: ${props => props.isNoMaxWidth ? '100%' : '1200px'};
  margin: 170px auto 156px;
  padding: ${props => props.isNoMaxWidth ? '0' : '0 20px'};
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  ${props => props.theme.media.tab} {
    margin: 100px auto;
  }
`;

const TitleWrap = styled.div<{ isNoMaxWidth: boolean }>`
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: ${props => props.isNoMaxWidth ? '0 15px' : '0'};
`;

const Title = styled.h1`
  font-size: 6.4rem;
  letter-spacing: 0.1em;
  font-family: 'Marcellus', serif;
  ${props => props.theme.media.tab} {
    font-size: 4.4rem;
  }
`;

const TitleSub = styled.h2`
  font-size: 1.4rem;
  letter-spacing: 0.2em;
  margin-top: 10px;
  margin-bottom: 110px;
  ${props => props.theme.media.tab} {
    margin-bottom: 55px;
    font-size: 1.2rem;
  }
`;

type Props = {
  title: string;
  titleSub: string;
  isNoMaxWidth?: boolean;
  children: React.ReactNode;
};

const Static: React.FC<Props> = (props: Props) => (
  <Wrap isNoMaxWidth={!!props.isNoMaxWidth}>
    <TitleWrap isNoMaxWidth={!!props.isNoMaxWidth}>
      <SlideText>
        <Title>{props.title}</Title>
      </SlideText>
      <SlideText delay="100" duration="1400">
        <TitleSub>/&nbsp;{props.titleSub}</TitleSub>
      </SlideText>
    </TitleWrap>
    {props.children}
  </Wrap>
);

export default Static;
