import React from 'react';
import styled from 'styled-components';
import { ValuesListData } from 'components/variables/values';
import StaticTemplate from 'components/Templates/Static';
import SlideText from 'components/LV1/AosSlideText';
import Image from 'components/LV1/image';
import BgValue from "src/images/about/bg-about-value.jpg";

const listOutlineLeft = [
  { no: '1', title: '会社名', text: 'コクラボ株式会社 (Coc-Labo Inc.)', },
  { no: '2', title: '設立日', text: '2019年1月17日', },
  { no: '3', title: '資本金', text: '1,000,000円', },
  { no: '4', title: '代表者', text: '代表取締役 石月 裕昭', },
  { no: '5', title: '所在地', text: '東京都渋谷区', },
];

const Wrap = styled.div`
  width: 100%;
`;

const Inner = styled.div`
  position: relative;
  margin: auto;
`;

const FvWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: auto;
`;

const ImageWrap = styled.div`
  position: absolute;
  width: 670px;
  right: -260px;
  z-index: 3;
  &.sp-only {
    display: none;
  }
  ${props => props.theme.media.tab} {
    display: none;
    &.sp-only {
      display: block;
      width: 160px;
      height: auto;
      right: 0;
    }
  }
`;

const TextWrap = styled.div`
  display: flex;
  position: relative;
  z-index: 4;
  padding: 180px 0 0 80px;
  .title {
    position: relative;
    font-size: 3.6rem;
    letter-spacing: 0.075em;
    font-family: 'Marcellus', serif;
    &:before {
      position: absolute;
      content: 'MISSION';
      font-family: 'Marcellus', serif;
      font-size: 15rem;
      letter-spacing: 0.05em;
      color: #efe9e6;
      top: -90px;
      left: -44px;
      z-index: -1;
    }
  }
  .text {
    margin-left: 50px;
    line-height: 2.5;
    letter-spacing: 0.1em;
  }
  &.vision {
    padding: 200px 0 170px 380px;
    .title {
      &:before {
        content: 'VISION';
      }
    }
  }
  ${props => props.theme.media.tab} {
    display: block;
    padding: 130px 15px 0 42px;
    .title {
      font-size: 3.5rem;
      &:before {
        font-size: 10rem;
        top: -74px;
        left: -54px;
        color: #e3dbd7;
      }
    }
    .text {
      margin-top: 15px;
      margin-left: 0;
      font-size: 1.5rem;
      line-height: 2.2;
    }
    &.vision {
      padding: 140px 0 120px 100px;
    }
  }
  ${props => props.theme.media.tabSp} {
    .text {
      br {
        display: none;
      }
    }
  }
`;

const ValueWrap = styled.div`
  position: relative;
  z-index: 2;
  background-image: url(${BgValue});
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  .inner {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 120px 15px;
    ${props => props.theme.media.sp} {
      padding: 180px 15px;
    }
    .title-wrap {
      color: #fff;
      .title {
        font-size: 5.4rem;
        letter-spacing: 0.1em;
        font-family: 'Marcellus', serif;
        ${props => props.theme.media.tab} {
          font-size: 3.6rem;
        }
      }
      .title-sub {
        font-size: 1.4rem;
        letter-spacing: 0.2em;
        margin-top: 10px;
        ${props => props.theme.media.tab} {
          font-size: 1.3rem;
        }
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      li {
        position: relative;
        width: calc(50% - 80px);
        color: #fff;
        margin-top: 170px;
        margin-right: 0;
        padding: 60px;
        background-color: #2c2c2c;
        box-shadow: 4px 4px 10px #181818;
        &:nth-child(odd){
          margin-top: -64px;
          margin-right: 160px;
        }
        &:first-child {
          margin-top: 84px;
        }
        &:nth-child(2) {
          margin-top: 280px;
        }
        .num {
          position: absolute;
          top: -30px;
          left: 60px;
          font-size: 4.8rem;
          letter-spacing: 0.075em;
          font-family: 'Marcellus', serif;
        }
        .title {
          font-size: 4rem;
          letter-spacing: 0.075em;
          font-family: 'Marcellus', serif;
        }
        .title-sub {
          font-size: 1.4rem;
          letter-spacing: 0.2em;
          margin: 12px 0 45px;
        }
        p {
          font-size: 1.6rem;
          letter-spacing: 0.1em;
          line-height: 1.75;
          color: #a5a5a5;
        }
      }
    }
    ${props => props.theme.media.pc} {
      ul {
        li {
          width: calc(50% - 40px);
          padding: 60px 30px 30px;
          &:nth-child(odd){
              margin-right: 80px;
          }
        }
      }
    }
    ${props => props.theme.media.tab} {
      ul {
        display: block;
        li {
          width: 100%;
          margin: 90px auto 0;
          &:nth-child(odd),
          &:first-child,
          &:nth-child(2) {
            width: 100%;
            margin: 90px auto 0;
          }
          .num {
            left: 30px;
          }
          .title {
            font-size: 3.8rem;
          }
          .title-sub {
            margin: 12px 0 30px;
          }
          p {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
`;

const OutlineWrap = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 130px auto 100px;
  padding: 0 15px;
  display: flex;
  ${props => props.theme.media.max} {
    flex-wrap: wrap;
  }
  ${props => props.theme.media.tab} {
    display: block;
  }
  ${props => props.theme.media.sp} {
    margin: 75px auto 50px;
  }
`;

const SectionTitle = styled.div`
  position: relative;
  height: fit-content;
  padding-right: 70px;
  white-space: nowrap;
  border-right: 1px solid #000;
  z-index: 1;
  p {
    &.main {
      font-size: 5.4rem;
      letter-spacing: 0.1em;
      font-family: 'Marcellus', serif;
    }
    &.sub {
      font-size: 1.4rem;
      letter-spacing: 0.2em;
    }
  }
  ${props => props.theme.media.tab} {
    padding: 0;
    text-align: center;
    border-right: unset;
  }
  ${props => props.theme.media.sp} {
    text-align: left;
  }
`;

const OutlineList = styled.ul`
  position: relative;
  z-index: 3;
  flex-shrink: 0;
  width: 40%;
  font-size: 1.6rem;
  letter-spacing: 0.18em;
  margin-left: 70px;
  &.right {
    margin-left: 0;
  }
  li {
    display: flex;
    flex-wrap: wrap;
    .title {
      width: 86px;
    }
    .text {
      width: calc(100% - 86px);
      margin: 0 0 30px 0;
      &.no-margin {
        margin: 0;
      }
    }
    &:last-child {
      .text {
        margin: 0 0 0 0;
      }
    }
  }
  .detail-list {
    padding-left: 20px;
    li {
      position: relative;
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 20px;
      }
      &:before {
        position: absolute;
        content: '●';
        font-size: 10px;
        top: 0.5em;
        left: -15px;
        font-family: Arial;
      }
    }
  }
  ${props => props.theme.media.max} {
    width: calc(100% - 360px);
    &.right {
      margin: 30px 0 0 360px;
    }
  }
  ${props => props.theme.media.tab} {
    width: 100%;
    max-width: 450px;
    margin: 30px auto 0;
    &.right {
      margin: 30px auto 0;
    }
  }
  ${props => props.theme.media.sp} {
    li {
      .text {
        margin: 0 0 15px 0;
      }
    }
    &.right {
      margin: 20px auto 0;
    }
  }
`;

const NamingWrap = styled.div`
  margin-bottom: -156px;
  background-color: #fff;
  .inner {
    position: relative;
    z-index: 3;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 120px 15px;
    display: flex;
  }
  ${props => props.theme.media.tab} {
    margin-bottom: -100px;
    .inner {
      display: block;
      padding: 60px 15px;
    }
  }
`;

const Description = styled.div`
  font-size: 1.6rem;
  letter-spacing: 0.18em;
  margin-left: 70px;
  ${props => props.theme.media.tab} {
    margin: 30px auto 0;
  }
`;

const About: React.FC = () => {
  return (
    <StaticTemplate
      title="About Us"
      titleSub="わたしたちについて"
      isNoMaxWidth
    >
      <Wrap>
        <Inner>
          <FvWrap>
            <ImageWrap data-aos="fade-up" data-aos-duration="3000" data-aos-once="false">
              <Image filename="about/img-about-right.jpg" alt="image right bg" />
            </ImageWrap>
            <ImageWrap className="sp-only" data-aos="fade-up" data-aos-duration="3000" data-aos-once="false">
              <Image filename="about/img-about-right-sp.jpg" alt="image right bg" />
            </ImageWrap>
            <TextWrap data-aos="fade-up" data-aos-duration="1000">
              <div className="title"><SlideText delay="500">MISSION</SlideText></div>
              <div className="text">
                <SlideText delay="1000" duration="1600">
                  ITを通じてさまざまな方々と共に価値を創造し続け、
                  <br />
                  より豊かな未来を創造する。
                </SlideText>
              </div>
            </TextWrap>
            <TextWrap className="vision" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="1000">
              <div className="title"><SlideText delay="1500">VISION</SlideText></div>
              <div className="text">
                <SlideText delay="2000" duration="1600">
                  課題や問題の本質を見極め、
                  <br />
                  ITを通じてシンプルに解決し
                  <br />
                  さまざまな方々と共に新たな価値を創造する。
                </SlideText>
              </div>
            </TextWrap>
          </FvWrap>
          <ValueWrap>
            <div className="inner">
              <div className="title-wrap">
                <div className="title">
                  <SlideText>
                    Value
                  </SlideText>
                </div>
                <div className="title-sub">
                  <SlideText delay="100" duration="1400">
                    / 大切にしている9つの価値観
                  </SlideText>
                </div>
              </div>
              <ul>
                {ValuesListData.map((item, index) => (
                  <li key={item.no} data-aos="fade-up" data-aos-duration="1000" data-aos-once="false">
                    <div className="num"><SlideText delay="100">{item.no}</SlideText></div>
                    <div className="title"><SlideText delay="300">{item.title}</SlideText></div>
                    <div className="title-sub"><SlideText delay="500">{item.titleSub}</SlideText></div>
                    <p><SlideText delay="700">{item.text}</SlideText></p>
                  </li>
                ))}
              </ul>
            </div>
          </ValueWrap>
          <OutlineWrap>
            <SectionTitle>
              <p className="main">
                <SlideText>
                  Outline
                </SlideText>
              </p>
              <p className="sub">
                <SlideText delay="100" duration="1400">
                  /&nbsp;会社概要
                </SlideText>
              </p>
            </SectionTitle>
            <OutlineList>
              {listOutlineLeft.map((item, index) => (
                <li key={item.no} data-aos="fade-up" data-aos-duration="1000" data-aos-once="false">
                  <div className="title">{item.title}</div>
                  <div className="text">{item.text}</div>
                </li>
              ))}
            </OutlineList>
            <OutlineList className="right">
              <li data-aos="fade-up" data-aos-duration="1000" data-aos-once="false">
                <div className="title">事業内容</div>
                <div className="text no-margin">
                  <ul className="detail-list">
                    <li>ITコンサルティング</li>
                    <li>デジタルマーケティング</li>
                    <li>システム開発</li>
                    <li>プロダクト開発</li>
                    <li>クリエイティブ制作</li>
                    <li>運用・保守</li>
                    <li>ITサポート</li>
                  </ul>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-duration="1000" data-aos-once="false">
                <div className="title">E-mail</div>
                <div className="text">info@coc-labo.co.jp</div>
              </li>
              <li data-aos="fade-up" data-aos-duration="1000" data-aos-once="false">
                <div className="title">WEB</div>
                <div className="text">https://coc-labo.co.jp</div>
              </li>
            </OutlineList>
          </OutlineWrap>
          {/* TODO: あとで適用
          <NamingWrap>
            <div className="inner">
              <SectionTitle>
                <p className="main">
                  <SlideText>
                    CLIENT
                  </SlideText>
                </p>
                <p className="sub">
                  <SlideText delay="100" duration="1400">
                    /&nbsp;取引先
                  </SlideText>
                </p>
              </SectionTitle>
              <Description>
                <SlideText>
                  AAAA
                </SlideText>
              </Description>
            </div>
          </NamingWrap> */}
          <NamingWrap>
            <div className="inner">
              <SectionTitle>
                <p className="main">
                  <SlideText>
                    Naming
                  </SlideText>
                </p>
                <p className="sub">
                  <SlideText delay="100" duration="1400">
                    /&nbsp;「コクラボ」の由来
                  </SlideText>
                </p>
              </SectionTitle>
              <Description>
                <SlideText>
                  ITを通じて様々な方々と共に価値を創造したい。
                  <br />
                  現状に満足せず、常に前進し続けたい。
                  <br />
                  という思いから、コクラボ[Co-Creation Laboratory(価値共創研究所)]と定義しました。
                </SlideText>
              </Description>
            </div>
          </NamingWrap>
        </Inner>
      </Wrap>
    </StaticTemplate >
  );
};
export default About;
