import React, { Fragment } from 'react';
import Head from 'components/LV1/Seo';
import About from 'components/LV3/About';

const AboutPage: React.FC = () => (
  <Fragment>
    <Head
      title="ABOUT"
      description="コクラボ株式会社の会社紹介ページ。コクラボ株式会社はスピード・品質を重視したワンストップ開発を強みとしているIT企業です。スタートアップ・ベンチャーから大手企業様まで、さまざまな種類のWebサイトやWebサービスを企画・開発しています。クリエイティブ・マーケティング・広告・データ分析などの領域も対応可能です。"
    />
    <About />
  </Fragment>
);
export default AboutPage;